import {API_ACTIONS, API_URL} from "../constants.js";
import {getCookie} from "../functions.js";

export const getPayoutRequest = async ({params, request}) => {
    try {
        return await fetch(`${API_URL}/${API_ACTIONS.getPayout}/${params.id}`, {signal: request.signal});
    } catch (e) {
        console.log("ERROR  ", e)
    }
    return {code: 404};
}

export const submitPayout = async ({number, csrf, _id}) => {
    let cookieCsrf = getCookie('_csrf')
    if (!number) {
        return false;
    }
    console.log({
        number,
        csrf,
        cookieCsrf,
        _id
    })
    try {
        const response = await fetch(`${API_URL}/${API_ACTIONS.submitPayout}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': csrf
            },
            body: JSON.stringify({
                pan: number,
                requestId: _id,
                _csrf: csrf
            })
        });
        const result = await response.json();
        console.log("RESULT ", result)

        return result?.data;
    } catch (e) {
        console.log("ERROR  ", e)

    }
    return false;
}